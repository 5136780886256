import CobrowseIO from 'cobrowse-sdk-js';
import { useEffect } from 'react';
import { useCobrowseStore } from '@src/store/appState';
import useUserSession from '@src/hooks/useUserSession';
import getConfig from '@src/util/getConfig';

const CobrowseProvider = () => {
    const {
        isInitialized, setInitialized, setSession, setCobrowseIO,
    } = useCobrowseStore();
    const { user } = useUserSession();
    const config = getConfig();
    CobrowseIO.license = config.cobrowse_license_key;
    useEffect(() => {
        if (!isInitialized && user && config.cobrowse_license_key) {
            CobrowseIO.customData = {
                user_id: `#${user.id}`,
                user_email: user.user_email,
                user_name: `${user.user_first_name} ${user.user_last_name}`,
                partner: config.gatsby_entity_name,
            };
            CobrowseIO.on('session.updated', (session) => {
                if (session.state() === 'active') {
                    setSession(session);
                } else {
                    setSession(null);
                }
            });
            CobrowseIO.start();
            setCobrowseIO(CobrowseIO);
            setInitialized(true);
        } else if (isInitialized && !user) {
            CobrowseIO.end();
            setInitialized(false);
        }
    }, [isInitialized, user]);

    return null;
};

export default CobrowseProvider;
