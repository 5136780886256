import { useDescope, useSession } from '@descope/react-sdk';
import { useSessionStore } from '@src/store/appState';
import { useState, useEffect } from 'react';

// This hook logs out current user when user follows a magic link
const useSessionManager = () => {
    const [isSSR, setIsSSR] = useState(true);
    useEffect(() => {
        setIsSSR(false);
    }, []);

    // useSession automatically refreshes the session token when it is about to expire
    // This is done in the background using the refresh token, without any additional configuration
    // https://github.com/descope/react-sdk
    const { isAuthenticated, isSessionLoading, sessionToken } = useSession();

    const manageSessions = useSessionStore((state) => state.manageSessions);
    const isResolving = useSessionStore((state) => state.isResolving);
    const sessionResolverPromise = useSessionStore((state) => state.sessionResolverPromise);
    const { logout, magicLink, getSessionToken } = useDescope();

    useEffect(() => {
        if (!isSessionLoading && !sessionResolverPromise) {
            manageSessions({
                isAuthenticated,
                isSessionLoading,
                logout,
                magicLink,
            });
        }
    }, [isSessionLoading, sessionResolverPromise]);

    // Sometimes Descope's useSession has broken state despite having a valid token. This may occur after magicLink.verify
    // For now we can grab the token straight from storage and later auto-refresh will ensure it works properly
    const token = isSSR ? sessionToken : getSessionToken();

    return {
        isAuthenticated: !!token,
        isSessionLoading: isResolving || isSessionLoading,
        isSessionClean: !isResolving,
        sessionToken: token,
    };
};

export default useSessionManager;
