import { useDescope } from "@descope/react-sdk";
import { useGetCurrentUserQuery } from "@src/apiFetch/authApi";
import { navigate } from "gatsby";
import { useCallback } from "react";
import { useEffect } from "react";
import useSessionManager from '@src/hooks/useSessionManager';

export type User = {
    user_first_name: string;
    user_last_name: string;
    email: string;
    id: string;
}

const useUserSession = () => {
    const { isAuthenticated, isSessionLoading } = useSessionManager();
    // const { isAuthenticated, isSessionLoading } = useSession()
    const { logout: descopeLogout, getSessionToken } = useDescope()
    const logOut = useCallback(() => {
        (async () => {
            await descopeLogout();
            navigate('/');
        })();
    }, [descopeLogout])

    const { data: user, isLoading } = useGetCurrentUserQuery({
        enabled: isAuthenticated && !isSessionLoading,
        staleTime: 6000
    });
    useEffect(() => {
        if (window.zipy && user) {
            window.zipy.identify(`${user.id}`, { email: `${user.user_email}` })
        }
    }, [user]);

    return {
        isLoggedIn: isAuthenticated,
        isRetrievingUser: isLoading,
        logOut,
        getAccessToken: getSessionToken,
        user,
    }
}

export default useUserSession;
